<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Шкала" prop="parent_id">
      <el-select
        v-model="scale.parent_id"
      >
        <el-option
          v-for="template in scaleTemplates"
          :key="template.id"
          :label="template.name"
          :value="template.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Единица измерения" prop="unit">
      <element-scale-unit-select
        v-model="scale.unit"
        style="width: 100%"
      ></element-scale-unit-select>
    </el-form-item>

    <el-form-item label="Плановое значение" prop="plan">
      <el-input
        v-model="scale.plan"
      ></el-input>
    </el-form-item>

    <el-form-item label="Фактическое значение" prop="fact">
      <el-input
        v-model="scale.fact"
      ></el-input>
    </el-form-item>

    <el-form-item label="Ответственный" prop="responsible">
      <el-input
        v-model="scale.responsible"
      ></el-input>
    </el-form-item>

  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import requestSender from "@/api/base/requestSender";
import ElementScaleUnitSelect from "@/componentsCompany/cherkizovo/elementScales/ElementScaleUnitSelect.vue";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {ElementScaleUnitSelect},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    });
    requestSender('get', 'scale/templates-list', {})
      .then(data => {
        // У нас может быть удаленный или залоченный темплейт. Нужно его тоже пушнуть в массив
        if (this.scale.parent) {
          if (!data.scales.progressive.find(t => t.id === this.scale.parent.id)) {
            data.scales.progressive.push({
              id: this.scale.parent.id,
              name: this.scale.parent.name,
            })
          }
        }
        this.scaleTemplates = data.scales.progressive;
      })
  },

  data() {
    return {
      scaleTemplates: [],

      scale: {
        parent: null,

        parent_id: null,
        unit: null,
        plan: null,
        fact: null,
        responsible: null,
      },

      rules: {
        parent_id: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        unit: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        plan: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
      }

    }
  },

  methods: {}
}
</script>

<style>

</style>